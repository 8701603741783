import * as React from "react"
import styled from 'styled-components';
import "@fontsource/open-sans"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import scrollTo from 'gatsby-plugin-smoothscroll';

// Components
import { breakpoint } from "../components/dimensions"
import abiJson from '../abi/Triple1.json'; // abi

import { NameCard, NameCardGridContainer } from "../components/NameCard"
import GridContainer from "../components/GridContainer"
import ClockOrMint from "../components/ClockOrMint"

// Images
import bgImage from '../images/background.svg'
import bgImageTop from '../images/background_mob_top.svg'
import bgImageBot from '../images/background_mob_bot.svg'
import twitterIcon from '../images/twitter_icon.svg'
import instagramIcon from '../images/instagram_icon.svg'
import discordIcon from '../images/discord_icon.svg'
import bannerTriple1 from '../images/banner_triple1.svg'
import bannerAnimation from '../images/banner_kill_covid_desktop.jsx'
import bannerCovid from '../images/banner_covid.svg'
import titleOurMission from '../images/title_our_mission.svg'
import nft from '../images/nft.svg'

import titleMeetTheTeam from '../images/title_meet_the_team.svg'
import teamAlan from "../images/team/team_alan.svg"
import teamAshwick from "../images/team/team_ashwick.jpg"
import teamBillynosurprise from "../images/team/team_billynosurprise.svg"
import teamCharlie from "../images/team/team_charlie_update.jpg"
import teamDennis from "../images/team/team_dennis.svg"
import teamDilly from "../images/team/team_dilly.svg"
import teamDucky from "../images/team/team_ducky.svg"
import teamEllis from "../images/team/team_ellis.svg"
import teamErik from "../images/team/team_erik_update.jpg"
import teamEva from "../images/team/team_eva.jpg"
import teamJkl852 from "../images/team/team_jkl852.svg"
import teamMichael from "../images/team/team_michael.svg"
import teamStephen from "../images/team/team_stephen.svg"
import teamTanky from "../images/team/team_tanky.svg"
import teamTerry from "../images/team/team_terry.svg"
import teamWilson from "../images/team/team_wilson_update.jpg"



// Variables
const netId = '1';
const etherscanURLprefix = "https://etherscan.io/tx/"

const StartDate = new Date("2022-01-11T15:00:00Z");
const EndDate = new Date("2022-03-07T15:00:00Z");


// Styles

const Main = styled.div`
  background-image: url(${bgImage});
  background-size: 100%; //propotional resize
  padding: 34px;

  @media (max-width: ${breakpoint}) {
    background-image: url(${bgImageTop});
    padding: 20px;
    background-repeat: no-repeat;
    background-color: #6AC2CE;
  }
`

const MainBottom = styled.img`
  display: none;
  background-size: 100%; //propotional resize

  @media (max-width: ${breakpoint}) {
    display: unset;
    background-color: #6AC2CE;
    width: 100%;
    bottom: 0;
    margin-top: -100%;
  }
`

const Section = styled.section`
  width: 100%;
  margin: auto auto 100px auto;
  box-sizing: border-box;

  @media screen and (max-width: ${breakpoint}) {
    /* padding-left: 4px; */
    /* padding-right: 4px; */
  }

  position: relative;
`;

const CenterH = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoint}) {
    height: fit-content;
  }
  @media (max-width: 510px) {
    height: fit-content;
  }
  
  @media (max-width: 340px) {
    max-height: 100px;
  }
`

const TwitterButton = styled.button`
  background-image: url(${twitterIcon});
  background-repeat: no-repeat;
  background-size: cover;
  border-width: 0px;
  background-color: transparent;
  margin-right: 50px;
  height: 31px;
  width: 38px;
  cursor: pointer;
  &:hover {
    filter: invert(0.9);
  }
`
const InstagramButton = styled.button`
  background-image: url(${instagramIcon});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  height: 31px;
  width: 31px;
  margin-right: 50px;
  border-width: 0px;
  cursor: pointer;
  &:hover {
    filter: invert(0.9);
  }
`
const DiscordButton = styled.button`
  background-image: url(${discordIcon});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  height: 32px;
  width: 44px;
  border-width: 0px;
  cursor: pointer;
  &:hover {
    filter: invert(0.9);
  }
`


const hButton = styled.button`
  background-color: transparent;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;

  border-radius: 5px;

  padding: 8px;
  margin: auto 8px;
  cursor: pointer;

  &:hover {
    border-radius: 5px;
    background-color: #FFFFFF;
    border: 2px solid transparent;
    color:#FFB400;
  }

`

const OurMissonButton = styled(hButton)`
  border: 2px solid transparent;

`
const TheTeamButton = styled(hButton)`
  border: 2px solid transparent;
`

const NavSection = styled(Section)`
  margin: auto auto auto auto;
`

const NavContainer = styled(GridContainer)`
  
  @media (max-width: ${breakpoint}) {
    width: 100%;
    grid-template-columns: 1fr;
    
    div {
      height: 50%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      //margin: 14px;
      margin: auto;
    }
  }
`

const Banner = styled.img`
  float: right;
  width: 55%;
  margin: auto 140px auto auto;
  z-index: 0;

  @media (max-width: ${breakpoint}) {
    width: 80%;
    margin: auto auto 50px auto;
  }
`

const BannerIcon = styled.img`
  display: block;
  margin: 14px auto;
  width: 5%;
`

const BannerTitle =styled(BannerIcon)`
  width: auto;
  @media (max-width: ${breakpoint}) {
    width: 100%
  }
`

const BannerAnimation = styled.div`
  position: absolute;
  width: 99%;
  top: 25%;
  animation: phase1 2s ease-in-out;
  

  #anger-baby-right,
  #anger-baby-right-desktop,
  #anger-baby-left,
  #anger-baby-left-desktop,
  #red-eyes-shade-baby-left,
  #red-eyes-shade-baby-right,
  #red-eyes-shade-baby-left_2,
  #red-eyes-baby-right,
  #red-eyes-baby-right-desktop,
  #red-eyes-baby-left,
  #red-eyes-baby-left-desktop,
  #blow,
  #blow-desktop,
  #lazer,
  #lazer-desktop,
  #bacteria-1-face,
  #bacteria-1-face-desktop,
  #bacteria-2-face,
  #bacteria-2-face-desktop,
  #bacteria-3-face,
  #bacteria-3-face-desktop,
  #bacteria-4-face-desktop,
  #bacteria-5-face-desktop,
  #bacteria-6-face-desktop {
    opacity: 0;
  }



  @keyframes phase1 {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(10px);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  /* Phase-2 */

  #anger-baby-right,
  #anger-baby-right-desktop,
  #anger-baby-left,
  #anger-baby-left-desktop {
    animation: phase2 0.5s ease-in-out forwards 3s,
      phase2anger 1s ease-in-out 3.5s, phase3opacityoff 0.5s 4.5s forwards;
  }

  #anger-baby-right,
  #anger-baby-right-desktop {
    transform-origin: right;
  }

  @keyframes phase2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes phase2anger {
    0% {
      transform: scaleX(1);
    }
    25% {
      transform: scaleX(1.2);
    }
    50% {
      transform: scaleX(0.8);
    }
    75% {
      transform: scaleX(1.2);
    }
    100% {
      transform: scaleX(1);
    }
  }

  /* Phase 3 */

  #black-eyes-baby-right,
  #black-eyes-baby-right-desktop,
  #black-eyes-baby-left,
  #black-eyes-baby-left-desktop {
    animation: phase3opacityoff 0.5s ease-in-out 4.5s forwards;
  }

  @keyframes phase3opacityoff {
    100% {
      opacity: 0;
    }
  }

  #red-eyes-baby-right,
  #red-eyes-baby-right-desktop,
  #red-eyes-baby-left,
  #red-eyes-baby-left-desktop {
    animation: phase3opacity 0.5s ease-in-out 4.5s forwards;
  }

  #red-eyes-shade-baby-left,
  #red-eyes-shade-baby-left-desktop,
  #red-eyes-shade-baby-right,
  #red-eyes-shade-baby-right-desktop,
  #red-eyes-shade-baby-left_2 {
    animation: phase3opacity 0.5s ease-in-out 4.5s forwards;
  }

  @keyframes phase3opacity {
    100% {
      opacity: 1;
    }
  }

  #red-eyes-baby-left-shade-desktop #Group,
  #Group_2,
  #Group_3,
  #Group_4,
  #Group_5,
  #Group_6,
  #Group_7,
  #Group_8,
  #Group_11,
  #Group_16,
  #Group_17,
  #Group_18,
  #Group_19,
  #Group_20,
  #Group_21,
  #Group_22,
  #Group_23,
  #Group_25,
  #Group_28 {
    animation: phase3redeyesshaderotate 2s linear 4.5s infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  @keyframes phase3redeyesshaderotate {
    100% {
      transform: rotatez(180deg);
    }
  }

  #lazer,
  #lazer-desktop {
    animation: phase3opacity 1s ease-in 4.5s forwards;
  }

  #blow,
  #blow-desktop,
  #bacteria-1-face,
  #bacteria-1-face-desktop,
  #bacteria-2-face,
  #bacteria-2-face-desktop {
    animation: phase3opacity 1s ease-in 5s forwards;
  }

  #bacteria-3-face,
  #bacteria-3-face-desktop,
  #bacteria-4-face-desktop,
  #bacteria-5-face-desktop,
  #bacteria-6-face-desktop {
    animation: phase3opacity 1s ease 5s forwards;
  }

`


const OurMissionContainer = styled(GridContainer)`
  div {
    margin: 2px 24px;
    img {
      margin: 16px auto;
    }
    @media (max-width: ${breakpoint}) {
      margin: auto;
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    }
  }
  @media (max-width: ${breakpoint}) {
    grid-template-columns: repeat(1, minmax(80px, 1fr))
  }
`


const Text = styled.div`
  white-space: pre-wrap;
  font-family: "Open Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: justify;

  p {
    margin-top: 20px;
  }
`

const Toaster = styled(ToastContainer)`
  --toastify-toast-width: 50%;
  @media (max-width: ${breakpoint}) {
    --toastify-toast-width: 80%
  }
`

function IndexPage(props) {
  return (
    <>

    <Main>
      <style jsx global>{`
        body {
          margin: 0px;
          padding: 0px;
          scroll-behavior: smooth;
        }
      `}</style>
      <Toaster />
      <NavSection>
        <NavContainer columns="1fr 1fr">
          <div>
            <a href="https://twitter.com/triple1nft" target="_blank"><TwitterButton src={twitterIcon} /></a>
            <a href="https://www.instagram.com/triple1nft/" target="_blank"><InstagramButton src={instagramIcon} /></a>
            <a href="https://discord.gg/CSNdJHGegM" target="_blank"><DiscordButton src={discordIcon} /></a>
          </div>
          
          <div style={{ marginLeft: 'auto', marginLight: 0 }}>
            <OurMissonButton onClick={() => scrollTo('#mission')}>Our Mission</OurMissonButton>
            <TheTeamButton onClick={() => scrollTo('#team')}>The Team</TheTeamButton>
          </div>
        </NavContainer>
      </NavSection>
      <Section>
        <BannerIcon src={bannerCovid} />
        <GridContainer columns="repeat(1fr)">
          <Banner src={bannerTriple1} alt="Triple1 logo"/>
          <BannerAnimation dangerouslySetInnerHTML={{__html: bannerAnimation}} />
          <CenterH>
            <ClockOrMint startdate={StartDate} enddate={EndDate} etherscanURLprefix={etherscanURLprefix} netId={netId} abiJson={abiJson}></ClockOrMint>
          </CenterH>
        </GridContainer>
      </Section>
      <a id="mission"></a>
      <Section>
        <OurMissionContainer columns="1fr 8fr 1fr">
          <div></div>
          <div>
            <BannerTitle src={titleOurMission} style={{ paddingBottom: "10px" }}></BannerTitle>
            <Text>
              <p>
              Hello and welcome! Thank you all for joining us today, Triple 1 is a charity event in the crypto community marking 11 January as ONE world ONE hope ONE action.
              </p>
              <p>
              ONE world - Involving all nations <br/>
              ONE hope - To drive out COVID-19 <br/>
              ONE action - United in our cause
              </p>
              <p>
              On 11 January 2022, we are united with ONE common goal in mind – To drive out the COVID-19 pandemic.
              </p>
              <p>
              ALL ETHs proceeds from minting the Triple 1 will be used for charitable donations, and after deducting for the cost of creating this charity event i.e. blockchain developers cost, will go towards hospitals and medical organisations (Additional ideas from the community with regards to allocating donations are appreciated and will be duly considered.)
              </p>
              <p>
              Frontline workers strive to keep our economy and communities operational throughout the coronavirus pandemic. Due to their continued efforts, we have been able to enjoy amenities such as the internet and food delivery services. Services that are critical to sustaining the work of the crypto community. We hereby dedicate 1 minute of silence in tribute to the frontline medical workers who selflessly gave their lives in the fight to save others during the COVID-19 pandemic.
              </p>
              <p>
              Triple 1 is a complimentary, digital and commemorative NFT dedicated to the memory of the fallen and it is NOT meant to be used as a pfp-based NFT for speculative activity.
              </p>

              <p>
              As this is a one-off charity event, Triple 1’s royalty rate will be 0% 
              </p>

              <p>
              In conclusion, our team would like to state that although we capitalize on other NFT projects, we are hosting the Triple 1 charity event with the sole purpose of making history in the crypto community. As the saying goes: With great power comes great responsibility. 
              </p>
              <p>
              Once again, good thoughts, good words and good deeds.
              </p>

            </Text>
          </div>
        </OurMissionContainer>
      </Section>
      <a id="team"></a>
      <Section>
        <BannerTitle src={titleMeetTheTeam} />
        <NameCardGridContainer columns="repeat(6, minmax(80px, 1fr))">
          <NameCard 
            background="#FF1010"
            src={teamTanky} 
            name="Tanky"
            title="Founder"
          />
          <NameCard 
            background="#FFFF00"
            src={teamJkl852} 
            name="Jkl852"
            title="Founder"
          />
          
          <NameCard 
            background="#1EB07C"
            color="white"
            src={teamErik} 
            name="Erik"
            title="Developer"
          />

          <NameCard 
            background= "#F6C95B"
            src={teamWilson} 
            name="Wilson"
            title="Developer"
          />

          <NameCard 
            background="#FF71D0"
            color="white"
            src={teamEva} 
            name="Eva"
            title="Designer"
          />

          <NameCard 
            background="#0000FF"
            color="white"
            src={teamBillynosurprise} 
            name="Billynosurprise"
            title="Designer" />

          <NameCard 
            background="#8CC63F"
            color="white"
            src={teamCharlie} 
            name="Charlie"
            title="Charity event manager"
          />

          <NameCard 
            background="#0071BC"
            color="white"
            src={teamDucky} 
            name="Ducky"
            title="Social media manager"
          />

          <NameCard 
            background="#F7931E"
            color="white"
            src={teamMichael} 
            name="Michael"
            title="Social media manager"
          />
          <NameCard 
            background="#FCEE21"
            src={teamAshwick} 
            name="Ashwick"
            title="Social media officer"
          />
          <NameCard 
            background="#603813"
            color="white"
            src={teamDilly} 
            name="Dilly"
            title="Social media officer"
          />
          <NameCard 
            background="#93278F"
            color="white"
            src={teamTerry} 
            name="Terry"
            title="Institutional relationship manager"
          />

          <NameCard 
            background="#FF1010"
            src={teamEllis} 
            name="Ellis"
            title="Institutional relationship manager"
          />


          <NameCard 
            background="#FFFF00"
            src={teamDennis} 
            name="Dennis"
            title="Narrative Designer"
          />

          <NameCard 
            background="#1EB07C"
            color="white"
            src={teamAlan} 
            name="Alan"
            title="Charity administrator"
          />
          
          <NameCard 
            background="#F6C95B"
            src={teamStephen} 
            name="Stephen"
            title="Charity administrator"
          />

        </NameCardGridContainer>
      </Section>
    </Main>
    <MainBottom src={bgImageBot}/>
    </>
  )
}

export default IndexPage
