import * as React from "react"
import styled from 'styled-components';
import "@fontsource/open-sans"
import { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in';

import ConnectNMintButton from '../components/ConnectNMintButton'
import GridContainer from "./GridContainer"
import { breakpoint } from "./dimensions"


const CountDown = styled.div`
  margin: 80px auto auto auto;
  text-align: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;

  color: #000000;
  z-index: 2;
  text-shadow: 
    -1px  -1px  0 #6bc2ce,
    1px   -1px  0 #6bc2ce,
    -1px  1px   0 #6bc2ce,
    1px   1px   0 #6bc2ce;

  @media (max-width: ${breakpoint}) {
    font-size: 16px;
    margin: auto;
  }
`


const ClockGrid = styled(GridContainer)`
    @media (max-width: ${breakpoint}) {
    margin-bottom: -100px;
    }
    @media (max-width: 340) {
    margin-bottom: -100px;
    }
`

const ExpireNotice = styled.div`
    margin: 120px auto 200px auto;
    text-align: left;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;

    color: #000000;
    position: relative;
    z-index: 4;

    @media (max-width: ${breakpoint}) {
        font-size: 16px;
        margin: auto;
    }
`


const ClockWrapper = styled.div`
  z-index: 2;
  text-align:center;
  margin: auto;

  @media (max-width: ${breakpoint}) {
    transform: scale(0.7) ;
  }
  @media (max-width: 510px) {
    transform: scale(0.4) translate(-42%,-42%);
    width: 140%;
  }
  @media (max-width: 340px) {
    transform: scale(0.4) translate(-50%,-50%);
    width: 160%;
  }
  
  .digit {
    width: 120px;
    height: 200px;
    margin: 0 -30px;
    position:relative;
    display:inline-block;
  }

  .digit .segment {
    background:transparent;
    border-radius:7px;
    position:absolute;
  }

  .digit .segment.on, .separator1 .separator2{
    background:#000000;
  }

  .separator1 {
    width:10px;
    height:10px;
    background:#000000;
    border-radius:50%;
    display:inline-block;
    position:relative;
    top:-143px;
    left: 5px;
  }
  .separator2 {
    width:10px;
    height:10px;
    background:#000000;
    border-radius:50%;
    display:inline-block;
    position:relative;
    top:-118px;
    right:5px;
  }

  .digit .segment:nth-child(1) {
    top:15px;
    left:40px;
    right:40px;
    height:10px;
    
  }

  .digit .segment:nth-child(2) {
    top:25px;
    right:32px;
    width:10px;
    height:40px;
  }

  .digit .segment:nth-child(3) {
    bottom:90px;
    right:32px;
    width:10px;
    height:40px;
    
  }

  .digit .segment:nth-child(4) {
    bottom:80px;
    right:40px;
    height:10px;
    left:40px;
    
  }

  .digit .segment:nth-child(5) {
    bottom:90px;
    left:32px;
    width:10px;
    height:40px;
  }

  .digit .segment:nth-child(6) {
    top:25px;
    left:32px;
    width:10px;
    height:40px;
  }

  .digit .segment:nth-child(7) {
    bottom:128px;
    right:40px;
    left:40px;
    height:10px;
  }
`


function Clock(props){
    const [timerDays, setTimerDays] = useState(0);
    const [timerHours, setTimerHours] = useState(88);
    const [timerMinutes, setTimerMinutes] = useState(88);
    const [timerSeconds, setTimerSeconds] = useState(88);

    const [uiSegmentsClassS1, setUiSegmentsClassS1] = useState(["segment","segment","segment","segment","segment","segment","segment"]);
    const [uiSegmentsClassS2, setUiSegmentsClassS2] = useState(["segment","segment","segment","segment","segment","segment","segment"]);
    const [uiSegmentsClassM1, setUiSegmentsClassM1] = useState(["segment","segment","segment","segment","segment","segment","segment"]);
    const [uiSegmentsClassM2, setUiSegmentsClassM2] = useState(["segment","segment","segment","segment","segment","segment","segment"]);
    const [uiSegmentsClassH1, setUiSegmentsClassH1] = useState(["segment","segment","segment","segment","segment","segment","segment"]);
    const [uiSegmentsClassH2, setUiSegmentsClassH2] = useState(["segment","segment","segment","segment","segment","segment","segment"]);
    var digitSegments = [
    [1,2,3,4,5,6],
    [2,3],
    [1,2,7,5,4],
    [1,2,7,3,4],
    [6,7,2,3],
    [1,6,7,3,4],
    [1,6,5,4,3,7],
    [1,2,3], 
    [1,2,3,4,5,6,7],
    [1,2,7,3,4,6]
    ]

    var setNumber = function() {
    // seconds
    let segmentsOnS2 = digitSegments[Math.floor((timerSeconds / 1) % 10)];
    let NewUiSegmentsClassS2 = uiSegmentsClassS2;
    for (var segNumber in uiSegmentsClassS2){
        if ( segmentsOnS2.includes(parseInt(segNumber)+1)){
        NewUiSegmentsClassS2[segNumber] = "segment on"
        }
        else{
        NewUiSegmentsClassS2[segNumber] = "segment"
        }
    }
    let segmentsOnS1 = digitSegments[Math.floor((timerSeconds / 10) % 10)];
    let NewUiSegmentsClassS1 = uiSegmentsClassS1;
    for (var segNumber in uiSegmentsClassS1){
        if ( segmentsOnS1.includes(parseInt(segNumber)+1)){
        NewUiSegmentsClassS1[segNumber] = "segment on"
        }
        else{
        NewUiSegmentsClassS1[segNumber] = "segment"
        }
    }

    //minutes
    let segmentsOnM2 = digitSegments[Math.floor((timerMinutes / 1) % 10)];
    let NewUiSegmentsClassM2 = uiSegmentsClassM2;
    for (var segNumber in uiSegmentsClassM2){
        if ( segmentsOnM2.includes(parseInt(segNumber)+1)){
        NewUiSegmentsClassM2[segNumber] = "segment on"
        }
        else{
        NewUiSegmentsClassM2[segNumber] = "segment"
        }
    }
    let segmentsOnM1 = digitSegments[Math.floor((timerMinutes / 10) % 10)];
    let NewUiSegmentsClassM1 = uiSegmentsClassM1;
    for (var segNumber in uiSegmentsClassM1){
        if ( segmentsOnM1.includes(parseInt(segNumber)+1)){
        NewUiSegmentsClassM1[segNumber] = "segment on"
        }
        else{
        NewUiSegmentsClassM1[segNumber] = "segment"
        }
    }

    //hours
    let segmentsOnH2 = digitSegments[Math.floor((timerHours / 1) % 10)];
    let NewUiSegmentsClassH2 = uiSegmentsClassH2;
    for (var segNumber in uiSegmentsClassH2){
        if ( segmentsOnH2.includes(parseInt(segNumber)+1)){
        NewUiSegmentsClassH2[segNumber] = "segment on"
        }
        else{
        NewUiSegmentsClassH2[segNumber] = "segment"
        }
    }
    let segmentsOnH1 = digitSegments[Math.floor((timerHours / 10) % 10)];
    let NewUiSegmentsClassH1 = uiSegmentsClassH1;
    for (var segNumber in uiSegmentsClassH1){
        if ( segmentsOnH1.includes(parseInt(segNumber)+1)){
        NewUiSegmentsClassH1[segNumber] = "segment on"
        }
        else{
        NewUiSegmentsClassH1[segNumber] = "segment"
        }
    }
    setUiSegmentsClassS2(NewUiSegmentsClassS2);
    setUiSegmentsClassS1(NewUiSegmentsClassS1);
    setUiSegmentsClassM2(NewUiSegmentsClassM2);
    setUiSegmentsClassM1(NewUiSegmentsClassM1);
    setUiSegmentsClassH2(NewUiSegmentsClassH2);
    setUiSegmentsClassH1(NewUiSegmentsClassH1);
    }
    const startTimer = () => {
    const countDownDate = props.startdate.getTime();
    const now = new Date().getTime();
    const distance = countDownDate - now;
    const days = Math.floor(distance / (24 * 60 * 60 * 1000));
    const hours = Math.floor((distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)) + (days * 24);
    const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
    const seconds = Math.floor((distance % (60 * 1000)) / 1000);

    if (distance < 0) {
        window.location.reload(true);
    } else {
        // Update Timer
        setTimerDays( days );
        setTimerHours( hours );
        setTimerMinutes( minutes );
        setTimerSeconds( seconds );
        if (seconds > 0){
        setNumber();
        }
    }
    };

    useEffect(() => {
    setTimeout(startTimer,1000);
    });

    return (
    <ClockGrid columns="1fr">
        <CountDown>Minting Count Down:</CountDown>
        <ClockWrapper>
            <div className="digit hours">
            {
                uiSegmentsClassH1.map((segment)=>{
                return <div className={segment}/>;
                })
            }
            </div>
            <div className="digit hours">
            {
                uiSegmentsClassH2.map((segment)=>{
                return <div className={segment}/>;
                })
            }
            </div>
            <div className="separator1"/>
            <div className="separator2"/>
            <div className="digit minutes">
            {
                uiSegmentsClassM1.map((segment)=>{
                return <div className={segment}/>;
                })
            }
            </div>
            <div className="digit minutes">
            {
                uiSegmentsClassM2.map((segment)=>{
                return <div className={segment}/>;
                })
            }
            </div>
            <div className="separator1"/>
            <div className="separator2"/>
            <div className="digit seconds">
            {
                uiSegmentsClassS1.map((segment)=>{
                return <div className={segment}/>;
                })
            }
            </div>
            <div className="digit seconds">
            {
                uiSegmentsClassS2.map((segment)=>{
                return <div className={segment}/>;
                })
            }
            </div>
        </ClockWrapper>
    </ClockGrid>
    );
}


function ClockOrMint(props){
    const countDownDate = props.startdate.getTime() + 2;
    const expireDate = props.enddate.getTime();
    const now = new Date().getTime();

    if (countDownDate - now > 0){
    return <div>
        <FadeIn delay={2000} transitionDuration={1000}>
            <Clock startdate={props.startdate}/>
        </FadeIn>
    </div>
    }
    else{
    if (expireDate > now){
        return <div>
        <ConnectNMintButton netId={props.netId} etherscanURLprefix={props.etherscanURLprefix} abiJson={props.abiJson}/>
        </div>
        }
        else{
        return <div>
            <ExpireNotice>Minting Period Has Ended</ExpireNotice>
        </div>
        }
    }
}


export default ClockOrMint;