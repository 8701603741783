import * as React from "react"
import styled from 'styled-components';
import "@fontsource/open-sans"

import GridContainer from "./GridContainer"
import { breakpoint } from "./dimensions"

export const NameCardGridContainer = styled(GridContainer)`
    @media (max-width: ${breakpoint}) {
    grid-template-columns: repeat(2, minmax(80px, 1fr))
    }
`

const NameCardWrapper = styled.div`
    margin-left: 34px;
    margin-right: 34px;

    @media (max-width: ${breakpoint}) {
        margin: auto 10%;
    }
`
const NameCardStyle = styled.div`
    /* height: 250px; */
    padding: 8px 8px;
    margin: 40px auto;
    width: 100%;
    height: 70%;
    border-radius: 0px;
    background: ${props => props.background} ;

    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;

    h3{
    color: ${props => props.color || "#000000"};
    };
    p{
    color: ${props => props.color || "#000000"};
    };

`

const NameCardImg = styled.img`
    /* display: flex; */
    margin-top: -56px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
`


export function NameCard(props) {
    return <NameCardWrapper>
        <NameCardStyle background={props.background} color={props.color}>
        <GridContainer columns="1fr 8fr 1fr">
            <div></div>
            <NameCardImg src={props.src} />
        </GridContainer>
        
        <h3> {props.name} </h3>
        <h3 style={{ fontStyle: 'italic' }}> {props.title} </h3>

        {props.children}
        </NameCardStyle>
    </NameCardWrapper>
}

export default {
    NameCard,
    NameCardGridContainer
}